import React from 'react'
import './About2.css'

const x = `  {
    "skills": {
      "UI": [ "bootstrap", "material-ui", "sass", "css" ],
      "frontend": [ "javascript", "redux", "reactjs", "next.js" ],
      "backend": [ "node.js", "python", "lua", "moonscrypt" ],
      "cloud": [ "aws", "firebase", "digital ocean", "linode" ],
      "bases de datos": [ "postgresql", "mariadb" ],
      "control de versiones": [ "git", "github", "bitbucket", "gitlab" ]
    }   
  }
`

export default class About extends React.Component {
  render(){
    return (
      <section id="about2" className="About2-container">
        <div className="container all-height no-padding">
          <div className="row justify-content-center align-items-center all-height About2-row no-margin">
            <div className="col-sm-11 col-md-10 col-lg-9 About2-code-container no-padding">
	      {x}
            </div>
          </div>
        </div>
      </section>
    )
  }
}
