import React, { Component } from 'react';
import './App.css';
import Home from './Home'
//import About from './About'
import About from './About2'
import Header from './Header'
//import Contact from './Contact'

class App extends Component {
  render() {
    return (
      <div className="App">
        <header>
	  <Home />
        </header>

	<Header />

        <div className="row no-margin">
          <div className="col-12 no-padding">
	    <About />
          </div>
          {/**<div className="col-12 no-padding">
	    <Contact />
          </div>**/}
        </div>
      </div>
    )
  }
}

export default App;
