import React from 'react'
import Typewriter from './Typewriter';
import './Home.css'
import hljs from 'highlight.js/lib/highlight';
import 'highlight.js/styles/agate.css';
import xml from 'highlight.js/lib/languages/xml';
hljs.registerLanguage('xml', xml);
hljs.initHighlightingOnLoad()

export default class Home extends React.Component {
  render(){
    return (
      <section id="home" className="Home-container">
        <div className="container all-height">
          <div className="row align-items-end no-margin height-50">
            <div className="col-10 Home-typewriter Home-title align-bottom">
	      <Typewriter
                strings={[ 'Hola,^1000\n soy Edmundo Andrade^1000\n Desarrollador web^1000\n']}
              />
            </div>
          </div>
          <div className="row align-items-start no-margin height-50">
            <div className="col-10 Home-typewriter Home-title align-top">
	      <a rel="noopener noreferrer" target="_blank" href="https://github.com/edmon1024" className="Home-icon fab fa-github-square">&nbsp;</a>
	      <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/sietivel" className="Home-icon fab fa-twitter-square">&nbsp;</a>
	      <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/edmundo-andrade-28645222" className="Home-icon fab fa-linkedin">&nbsp;</a>
	      {/**<a href="#contact" className="Home-icon fas fa-envelope-square">&nbsp;</a>**/}
            </div>
          </div>      
        </div>      
      </section>
    )
  }
}
